import React, { useState, useEffect, useContext } from "react";
import NotificationsList from "src/component/notifications";
import {
  Grid,
  Box,
  Button,
  Typography,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import Page from "src/component/Page";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import { toast } from "react-toastify";
import NoDataFound from "src/DataNotFound";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { useLocation } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import { useHistory, Link as RouterLink } from "react-router-dom";
export default function Notifications({ popUp }) {
  const [notificationList, setNotificationList] = useState([]);
  console.log("notificationList", notificationList);
  const auth = useContext(AuthContext);
  const userdata = auth.userData ? auth.userData : "";
  const [open, setopen] = useState(false);
  const [count, setCount] = useState([]);
  const history = useHistory();
  useEffect(() => {
    if (!window.localStorage.getItem("token")) {
      history.push("/login");
    }
  }, [window.localStorage.getItem("token")]);

  const [isLoading, setIsLoading] = useState(false);
  const getNotifictionList = async () => {
    setIsLoading(true);
    try {
      const res = await Axios.get(ApiConfig.getNotification, {
        headers: {
          authorization: `Bearer ${window.localStorage.getItem("token")}`,
          role: "USER",
        },
        params: {
          fkUserId: userdata?.userId,
        },
      });

      if (res.data.status === 1618) {
        setCount(res.data.data.filter((data) => !data.isSeen));
        setNotificationList(res.data.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err.response.data.responseMessage);
      console.error(err.response);
      setIsLoading(false);
    }
  };
  const clearNotifictionList = async (id) => {
    try {
      setIsLoading(true);
      const res = await Axios({
        method: "POST",
        url: ApiConfig.clearNotification,
        headers: {
          authorization: `Bearer ${window.localStorage.getItem("loginToken")}`,
          userId: id,
        },
      });
      if (res.data.status === 200) {
        toast.success(res.data.message);
        getNotifictionList();
        setNotificationList([]);
        // setNotificationList("null");
      } else {
        toast.warn(res.data.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("ERROR", error);
      toast.error("something went wrong");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userdata?.userId) {
      getNotifictionList();
    }
  }, [userdata?.userId]);
  useEffect(() => {
    setNotificationList([]);
  }, []);

  return (
    <Page title="Notifications">
      <Box className="classes.notificationHeading" >


        <Box mb={3}>
          {/* <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {popUp ? (
              ""
            ) : (
              <>
                <Box display="flex" className="notificationbox">
                  <Typography
                    variant="h5"
                    pt={2}
                    style={{ marginTop: "20px", fontSize: "30px", color: "rgb(25, 170, 230)", }}
                  >
                    Notifications
                  </Typography>
                </Box>
              </>
            )}
            {popUp
              ? ""
              : notificationList &&
              notificationList.length !== 0 && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginBottom: "-24px",
                  }}
                >
                  <Button onClick={() => setopen(true)}>clear all</Button>
                </Box>
              )}
          </Box> */}

          {popUp ? (
            <Box style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
              <Typography variant="h3" style={{ color: "rgb(25, 170, 230)", fontSize: "20px" }}>
                Notifications
              </Typography>

              <Typography variant="h3" onClick={() => setopen(true)} pt={2} style={{ color: "rgb(25, 170, 230)", fontSize: "18px", cursor: "pointer" }}>
                Clear all
              </Typography>
              {/* <Button onClick={() => setopen(true)}>clear all</Button> */}
              &nbsp;
              <Typography variant="h3" onClick={() => {
                history.push({
                  pathname: "/notifications",
                });
              }} pt={2} style={{ color: "#fff", fontSize: "18px", cursor: "pointer" }}>
                View all
              </Typography>
            </Box>
          ) : (
            ""
          )}



          {isLoading ? (
            <ButtonCircularProgress />
          ) : (
            <Grid container spacing={4} sm={12} xs={12} style={{ margin: "0", }}>

              {popUp
                ? notificationList.slice(0, 4).map((data, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      key={i}
                      style={{ padding: "0px", }}
                    >
                      <NotificationsList popUp={popUp} data={data} index={i} />
                    </Grid>
                  );
                })
                :




                <Box style={{ border: "1px solid rgb(25 170 230 / 33%)", background: "#040404", padding: "20px", width: "100%", borderRadius: "8px", marginTop: "60px" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {popUp ? (
                      ""
                    ) : (
                      <>
                        <Box display="flex" className="notificationbox">
                          <Typography
                            variant="h5"
                            pt={2}
                            style={{ marginTop: "20px", fontSize: "30px", color: "rgb(25, 170, 230)", }}
                          >
                            Notifications
                          </Typography>
                        </Box>
                      </>
                    )}
                    {popUp
                      ? ""
                      : notificationList &&
                      notificationList.length !== 0 && (
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            marginBottom: "-24px",
                          }}
                        >
                          <Button onClick={() => setopen(true)}>clear all</Button>
                        </Box>
                      )}
                  </Box>

                  <>
                    {notificationList &&
                      notificationList.map((data, i) => {
                        return (
                          <Grid item xs={12} sm={12} md={12} key={i}>
                            <Box style={{
                              padding: "8px", position: "relative", color: "rgb(0, 0, 0)",
                              borderRadius: "10px", backgroundColor: "rgb(34, 34, 34)", marginBottom: "10px", marginTop: "15px"
                            }}>
                              <NotificationsList popUp={popUp} data={data} index={i} />
                            </Box>
                          </Grid>
                        );
                      })}

                  </>




                </Box>

              }
            </Grid>
          )}
          {popUp ? (
            <>
              {" "}
              {!isLoading && notificationList && notificationList.length === 0 && (
                <Box style={{}}>
                  <Typography variant="h3" style={{ fontSize: "16px" }}>
                    No Data Found
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            ""
          )}
        </Box>
        <div>
          <Dialog
            open={open}
            onClick={() => setopen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="xs"
          >
            <Box align="center">
              <DialogTitle id="alert-dialog-title"><Typography variant="h2" style={{ color: "rgb(25, 170, 230)", }}>Clear All Notification</Typography></DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography variant="h4" style={{ color: "#fff", marginTop: "-16px", }}>Are you want to sure clear notification</Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{
                marginTop: "-2px",
                marginBottom: "20px"
              }}>
                <Button
                  variant="contained"
                  onClick={() => setopen(false)}
                  autoFocus
                  style={{ padding: "1px 20px" }}
                >
                  No
                </Button>
                <Button
                  variant="containedPrimary"
                  style={{ padding: "5px 20px", textTransform: "capitalize" }}
                  onClick={() => clearNotifictionList(userdata?.userId)}
                >
                  Yes
                </Button>
              </DialogActions>
            </Box>
          </Dialog>
        </div>
        {popUp ? (
          ""
        ) : (
          <>
            {!isLoading && notificationList && notificationList.length === 0 && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "center",
                  marginTop: "20px",
                }}
              >
                <Typography variant="h3" style={{ fontSize: "20px" }}>No Data Found</Typography>
              </Box>

       
            )}
          </>
        )}
      </Box>
    </Page>
  );
}
