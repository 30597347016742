

export default {
  h1: {
    fontWeight: 500,
    fontSize: 35,
    lineHeight:1.6,
    fontFamily: "'Nunito', sans-serif",
    color: "rgb(25, 170, 230)", 
  },
  h2: {
    fontWeight: 500,
    fontSize: 30,
    lineHeight:1.6,
    fontFamily: "'Nunito', sans-serif",

  },
  h3: {
    fontWeight: 500,
    fontSize: 25,
    lineHeight:1.6,
    fontFamily: "'Nunito', sans-serif",
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight:1.6,
    fontFamily: "'Nunito', sans-serif",
  },
  h5: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight:1.6,
    fontFamily: "'Nunito', sans-serif",
  },
  h6: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight:1.6,
    fontFamily: "'Nunito', sans-serif",
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
    fontFamily: "'Nunito', sans-serif",
  },
  body1: {
    fontSize: 16,
    lineHeight:1.6,
    fontWeight: "400",
    fontFamily: "'Nunito', sans-serif",
  },
  body2: {
    fontSize: 14,
    lineHeight:1.6,
    fontWeight: "400",
    fontFamily: "'Nunito', sans-serif",
  },
};
