import { Typography, Box, IconButton, Link } from "@material-ui/core";
import React, { useState, useContext } from "react";
import { FaRegTrashAlt, FaChevronRight, FaUserCheck } from "react-icons/fa";
import { MdVerifiedUser } from "react-icons/md";
import { BsArrowReturnRight, BsArrowReturnLeft } from "react-icons/bs";
import { GoUnverified } from "react-icons/go";

import { AuthContext } from "src/context/Auth";

import { Link as RouterLink } from "react-router-dom";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import moment from "moment";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { SiGooglemessages } from "react-icons/si";
export default function NotificationsList({ data, popUp }) {
  const [open, setOpen] = useState(false);

  const auth = useContext(AuthContext);
  const kycverified = data?.message?.includes(
    "Your KYC has been approved. Please start trading."
  );
  const dataTyperegistered = data?.message?.includes(
    "Your account has been created successfully."
  );
  const dataTypeWithdraw = data?.message?.includes("withdraw");
  const dataTypeDeposit = data?.message?.includes("received");
  const confirmationHandler = () => {
    setOpen(false);
  };
  return (
    <Box
      className="notication-list"
      style={
        popUp
          ? {
            // borderBottom: "1px solid rgb(234 183 59 / 36%)",
            marginTop: "15px",
            padding: "10px", position: "relative", color: "rgb(0, 0, 0)", borderRadius: "10px", backgroundColor: "#222222",
            // marginBottom: "10px",
          }
          : {
            // borderBottom: "1px solid rgb(234 183 59 / 36%)",

            position: "relative",
            color: "#000",
          }
      }

    >
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"title"}
          desc={"desc"}
          confirmationHandler={confirmationHandler}
        />
      )}

      <Box display="flex" alignItems="center">
        <Box>

          <IconButton
            style={
              kycverified
                ? {
                  backgroundColor: "green",
                  color: "#fff",
                  marginRight: "12px",
                }
                : {
                  backgroundColor: "#EE786C",
                  color: "#fff",
                  marginRight: "12px",
                }
            }
          >
            {/* <FaChevronRight size={14} /> */}
            {/* <NotificationsIcon size={14} /> */}
            {kycverified ? <MdVerifiedUser size={16} /> : ""}
            {dataTyperegistered ? <FaUserCheck size={16} /> : ""}
            {dataTypeWithdraw ? <BsArrowReturnRight size={16} /> : ""}
            {dataTypeDeposit ? <BsArrowReturnLeft size={16} /> : ""}
            {dataTyperegistered ? (
              ""
            ) : (
              <>
                {auth?.kycData?.documentStatus === "REJECTED" &&
                  auth?.kycData?.reason === null ? (
                  ""
                ) : (
                  <>
                    {auth?.kycData?.documentStatus === "REJECTED" ? (
                      // <GoUnverified size={16} />
                      <MdVerifiedUser size={16} />
                    ) : (
                      ""
                    )}
                  </>
                )}
              </>
            )}
          </IconButton>
        </Box>

        <Link
          to={{
            pathname: "/notifications",
            state: {
              data: data,
            },
          }}
          style={{ textDecoration: "none", width: "100%" }}
          component={RouterLink}
        >
          <Box>

            <Box >
              {/* <Typography variant="subtitle2" className="extra-bold">
                {kycverified ? "Verified Kyc" : ""}
                {dataTyperegistered ? "Registered" : ""}
                {dataTypeWithdraw ? "Withdraw" : ""}
                {dataTypeDeposit ? "Deposit" : ""}
                &nbsp;
                {dataTyperegistered ? (
                  ""
                ) : (
                  <>
                    {auth?.kycData?.documentStatus === "REJECTED" &&
                    auth?.kycData?.reason === null ? (
                      ""
                    ) : (
                      <>
                        {auth?.kycData?.documentStatus === "REJECTED" ? (
                          <>{`Reason :  ${auth?.kycData?.reason}`}</>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </>
                )}
              </Typography> */}
              {popUp ? (
                <Box>
                  <Typography variant="subtitle2" style={{ color: "rgb(25, 170, 230)", fontSize: "15px" }}>Lorem ipsum dolor sit amet,</Typography>
                  <Typography
                    variant="subtitle2"
                    pt={2}
                    style={{
                      // whiteSpace: "nowrap",
                      overflow: "hidden",
                      // whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: "400px",
                    }}
                  >
                    {data?.message.replace("AVT", "Toga")}
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography variant="subtitle2" style={{ color: "rgb(25, 170, 230)", fontSize: "15px", marginTop: "10px" }}>Lorem ipsum dolor sit amet,</Typography>
                  <Typography variant="subtitle2" pt={2} >
                    {data?.message?.replace("AVT", "Toga")}

                  </Typography>
                </Box>
              )}
            </Box>

            <Box mt={1}>
              <Typography variant="subtitle2" pt={2} style={{ color: "gray" }}>
                {data?.createdAt
                  ? moment(data.createdAt).format("MMMM Do YYYY, h:mm:ss a ")
                  : "0"}
              </Typography>
            </Box>


            

          </Box>
        </Link>
      </Box>
    </Box>
  );
}
