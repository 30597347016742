import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Grid, Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Page from "src/component/Page";
const useStyles = makeStyles((theme) => ({
  content: {
    height: "100vh",
    overflowX: "auto",

    color: "#fff",
  },
  left: {
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mainbox: {
    boxShadow: "0px 30px 30px rgba(0, 0, 0, 0.3)",
    backgroundColor: "#302F35",
    backdropFilter: "blur(42px)",
    backgroundImage: "url(images/login_banner.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    "& .signupmain": {
      marginTop: "-316px",
      "& h5": {
        fontWeight: "600",
      },
    },
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={5} className={classes.left}>
        <Box
          className={classes.mainbox}
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Box className="signupmain">
            <Box display="flex" justifyContent="center">
              <Typography variant="h5" style={{ fontSize: "30px" }}>
                Welcome Back
              </Typography>
            </Box>{" "}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ cursor: "pointer" }}
            >
              <img
                src="/images/logo.png"
                style={{ width: "188px" }}
                alt=""
                onClick={() => history.push("/")}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={7}>
        <Page title="Login">
          <Box className={classes.content}>{children}</Box>
        </Page>
      </Grid>
    </Grid>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
