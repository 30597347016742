import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Grid,
  Box,
  Typography
} from "@material-ui/core";

export default function AlertDialog({
  open,
  handleClose,
  title,
  desc,
  confirmationHandler,
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <Box align="center">
          <DialogTitle id="alert-dialog-title"><Typography variant="h2" style={{ color: "rgb(25, 170, 230)", }}>Logout</Typography></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="h4" style={{ color: "#fff",marginTop: "-16px", }}>Do you want to logout ?</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions align="center" style={{marginTop: "-2px", 
          marginBottom: "20px"}}>
         
            <Button
              variant="contained"
              onClick={handleClose}
              autoFocus
              style={{ padding: "1px 20px" }}
            >
              No
            </Button>
            <Button
              variant="containedPrimary"
              style={{ padding: "5px 20px", textTransform: "capitalize", height: "37px", }}
              onClick={() => {
                handleClose();
                confirmationHandler();
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}
